import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  discountList: [],
  nonDiscountUserList: [],
};

const slice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    getAllDiscountList(state, action) {
      state.discountList = action.payload.data;
    },
    getNonDiscountUserList(state, action) {
      state.nonDiscountUserList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getDiscountListAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `discounts/get-users-with-discounts`
      );
      dispatch(slice.actions.getAllDiscountList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getNonDiscountUserAction(handleLoading) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `discounts/get-users-without-discounts-v2`
      );
      dispatch(slice.actions.getNonDiscountUserList(response.data));
      if (handleLoading) {
        handleLoading();
      }
    } catch (error) {
      console.log(error);
      if (handleLoading) {
        handleLoading();
      }
    }
  };
}

export function addDiscountAction(data, handleUpdateRes) {
  return async () => {
    try {
      const response = await axiosInstance.post(`discounts/add-discount`, {
        discounts: data,
      });
      if (handleUpdateRes) {
        successMessage(response?.data?.message);
        handleUpdateRes(true);
      }
    } catch (error) {
      if (handleUpdateRes) {
        errorMessage(error?.response?.data?.message);
        handleUpdateRes(false);
      }
    }
  };
}
