import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  jobCriteriaList: [],
  allCriteriaList: [],
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    getJobCriteriaList(state, action) {
      state.jobCriteriaList = action.payload.data;
    },
    getAllCriteriaList(state, action) {
      state.allCriteriaList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getJobCriteriaAction(jobId, handleLoader) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `rating-criteria-users/get-rating-criterias-for-job-v2?jobId=${jobId}`
      );
      dispatch(slice.actions.getJobCriteriaList(response.data));
      if (handleLoader) {
        handleLoader();
      }
    } catch (error) {
      if (handleLoader) {
        handleLoader();
      }
    }
  };
}

export function getAllCriteriaAction(handleLoading) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `rating-criterias/?page=0&limit=10`
      );
      dispatch(slice.actions.getAllCriteriaList(response.data));
      if (handleLoading) {
        handleLoading();
      }
    } catch (error) {
      console.log(error);
      if (handleLoading) {
        // let errordisplay = error?.response?.data?.message || 'Something went wrong';
        // errorMessage(errordisplay);
        handleLoading();
      }
    }
  };
}

export function updateCriteriaOrderAction(data) {
  return async () => {
    try {
      await axiosInstance.patch(`rating-criterias/update-display-orders`, {
        ratingCriterias: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}
export function updateCriteriaStatusAction(id, status, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`rating-criterias/?id=${id}`, {
        active: status,
      });
      successMessage(response?.data?.message);
      if (handleClose) {
        handleClose(true);
      }
    } catch (error) {
      errorMessage(error?.response?.data?.message);
    }
  };
}

export function addCriteriaAction(
  data,
  damagesArray,
  categoryId,
  pdfIncludes,
  csvIncludes,
  includeCommonImages,
  includeCriteriaNotes,
  handleApiRes
) {
  return async () => {
    try {
      const response = await axiosInstance.post(`rating-criterias/`, {
        name: data.name,
        title: data.title,
        displayOrder: data.displayOrder,
        criteriaInfo: data.criteriaInfo,
        categoryId: categoryId,
        active: true,
        pdfIncludes: pdfIncludes,
        csvIncludes: csvIncludes,
        damageIds: damagesArray,
        includeCommonImages: includeCommonImages,
        includeCriteriaNotes: includeCriteriaNotes,
      });
      successMessage(response?.data?.message);
      handleApiRes();
    } catch (error) {
      handleApiRes(error);
      errorMessage(error?.response?.data?.message);
    }
  };
}

export function updateCriteriaAction(id, paramsData, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`rating-criterias/?id=${id}`, {
        name: paramsData.name,
        title: paramsData.title,
        displayOrder: paramsData.displayOrder,
        damageIds: paramsData.damagesArray,
        csvIncludes: paramsData.csvIncludes,
        pdfIncludes: paramsData.pdfIncludes,
        includeCommonImages: paramsData.includeCommonImages,
        includeCriteriaNotes: paramsData.includeCriteriaNotes,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      handleClose(error);
      errorMessage(error?.response?.data?.message);
    }
  };
}
