import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  r8trSortedList: [],
  r8trList: [],
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    getR8trList(state, action) {
      state.r8trSortedList = action.payload.data;
    },
    getUnSortedR8trList(state, action) {
      state.r8trList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getR8trSortedList(lat, lng, active, handleLoader) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `jobs/get-r8trs-by-distance?latitude=${lat}&longitude=${lng}&active=${active}`
      );
      dispatch(slice.actions.getR8trList(response.data));
      if (handleLoader) {
        handleLoader();
      }
    } catch (error) {
      if (handleLoader) {
        handleLoader();
      }
    }
  };
}

export function updateR8trStatus(id, paramsStatus) {
  return async () => {
    try {
      let data = {
        active: paramsStatus,
      };
      const response = await axiosInstance.patch(
        `users/update-user?id=${id}`,
        data
      );
      successMessage(response?.data?.message);
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };
}

export function R8trListAction(handleLoader) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `dashboards/get-all-r8trs-by-admin`
      );
      dispatch(slice.actions.getUnSortedR8trList(response.data));
      handleLoader();
    } catch (error) {
      errorMessage(error.response.data.message);
      handleLoader();
    }
  };
}
